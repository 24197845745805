import React, { useEffect } from "react"
import Layout from "../layouts/layout"
import Seo from "../components/seo"

import { CategoriesProvider } from "../contexts/categoriesContext"

import Card, { CardContent, CardHeader } from "../components/Card"
import CategoryTopicsForm from "../components/Forms/CategoryAndTopics"
import CategoriesTopicsList from "../components/List/categoriesTopicsList"
import SubscriptionForm from "../components/Forms/Subscription"
import Unsubscribe from "../components/Unsubscribe"
import Alert from "../components/Alert"
import Loading from "../components/Loader"
import Success from "../components/Response/success"
import Fail from "../components/Response/fail"
import API from "../api"
import { useManageSubscription } from "../contexts/manageContext"

// Page
function ManagePage({ location }) {
  return (
    <Layout page="manage">
      <Card>
        <CardHeader title="Welcome to Newsletter Subscription">
          Here you can subscribe to topics of interest to receive weekly emails
          in your inbox as a digest when new content is available.
          <a
            href="#/"
            className="font-medium text-indigo-600 hover:text-indigo-500 ml-1"
          >
            Learn More
          </a>
        </CardHeader>
        <CardContent>
          <Newsletter location={location} />
        </CardContent>
      </Card>
    </Layout>
  )
}

// Newsletter Subscription
const Newsletter = ({ location }) => {
  const {
    loading,
    pageLoading,
    submitted,
    response,
    responseText,
    getList,
    clearList,
    unsubscribe,
    setLoading,
    categoriesAndTopics,
    handleAdd,
    handleRemove,
    isSelectAll,
    clearAll,
    toggleSelectAll,
    recaptchaRef,
    handleUpdateSubscription,
    handleVerify,
    submitButtonVisible,
    handleEmailChange,
    isDisabled,
    isVerified,
    email,
  } = useManageSubscription()

  // // FetchData
  useEffect(() => {
    function findUserId() {
      const urlParams = new URLSearchParams(location.search)
      const userId = Number(urlParams.get("userId"))
      fetchData(userId)
    }

    function fetchData(userId) {
      console.log("load user data")
      API.get(`/subscriber/${userId}`)
        .then(response => {
          getList(response.data)
          console.log(response.data)
          setLoading(false)
        })
        .catch(error => console.log(error))
    }

    findUserId()

    return () => {
      clearList()
      setLoading(true)
      // UserData cleared from the State
    }
  }, [])

  const states = {
    categoriesAndTopics,
    email,
    isVerified,
    isDisabled,
    submitButtonVisible,
  }

  const handlers = {
    handleUpdateSubscription,
    handleVerify,
    handleEmailChange,
  }

  if (submitted && unsubscribe && response === "success") {
    return <Success title="Successfully Unsubscribed!" message={responseText} />
  } else if (submitted && response === "success") {
    return <Success message={responseText} />
  } else if (submitted && response === "fail") {
    return <Fail message={responseText} />
  } else {
    return (
      <MainForm pageLoading={pageLoading} loading={loading}>
        <CategoriesProvider>
          <CategoryTopicsForm
            categoriesAndTopics={categoriesAndTopics}
            handleAdd={handleAdd}
            isSelectAll={isSelectAll}
            clearAll={clearAll}
            toggleSelectAll={toggleSelectAll}
            message="All your existing subscriptions will be replaced by all categories & topics"
          />
        </CategoriesProvider>
        <CategoriesTopicsList
          loading={loading}
          categoriesAndTopics={categoriesAndTopics}
          isSelectAll={isSelectAll}
          handleRemove={handleRemove}
        />
        <Alert>
          You will be notified as a weekly digest when new summaries of your
          interest are available
        </Alert>
        <SubscriptionForm
          action="update"
          recaptchaRef={recaptchaRef}
          states={states}
          handlers={handlers}
        />
      </MainForm>
    )
  }
}

// Main Form
const MainForm = ({ pageLoading, children }) => {
  const { handleUnsubscribe } = useManageSubscription()

  return (
    <>
      <Loading isLoading={pageLoading} />
      {children}
      <Unsubscribe handleUnsubscribe={handleUnsubscribe} />
    </>
  )
}

// Seo
export const Head = () => <Seo title="New Manage" />

export default ManagePage
