import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { CardContent } from "../Card"

const Unsubscribe = ({ handleUnsubscribe, userId }) => {
  function handleSubmit(event) {
    event.preventDefault()
    handleUnsubscribe(userId)
  }

  return (
    <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-4">
      <CardContent>
        <p className="text-center text-sm text-gray-600 sm:px-4">
          If you wish to Unsubscribe from all emails, please use this option.
          Your selection will take effect from the next delivery cycle. Please
          note that this is a permanent process and cannot be reversed.
        </p>
      </CardContent>
      <form onSubmit={handleSubmit}>
        <button
          type="submit"
          className="pointer-events-auto rounded-md bg-red-600 py-2 px-3 text-base font-semibold leading-5 text-white hover:bg-red-800 w-full flex-none mt-4"
        >
          Unsubscribe
        </button>
      </form>
    </div>
  )
}

export const UnsubscribeAlt = ({
  handleUnsubscribe,
  userId,
  recaptchaRef,
  handleExpiry,
  handleVerify,
  sitekey,
  isVerified,
}) => {
  console.log(isVerified)
  const clickableButton = "block"
  const unclickableButton = "opacity-50 cursor-not-allowed"
  const buttonClass = isVerified ? clickableButton : unclickableButton

  function handleSubmit(event) {
    event.preventDefault()
    handleUnsubscribe(userId)
  }

  return (
    <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-4">
      <CardContent>
        <p className="text-center text-sm text-gray-600 sm:px-4">
          If you wish to Unsubscribe from all emails, please use this option.
          Your selection will take effect from the next delivery cycle. Please
          note that this is a permanent process and cannot be reversed.
        </p>
      </CardContent>
      <ReCAPTCHA
        className="mt-4"
        ref={recaptchaRef}
        sitekey={sitekey}
        onChange={handleVerify}
        onExpired={handleExpiry}
      />
      <form onSubmit={handleSubmit}>
        <button
          type="submit"
          className={`pointer-events-auto rounded-md bg-red-600 py-2 px-3 text-base font-semibold leading-5 text-white hover:bg-red-800 w-full flex-none mt-4 ${buttonClass}`}
          disabled={!isVerified}
        >
          Unsubscribe
        </button>
      </form>
    </div>
  )
}

export default Unsubscribe
